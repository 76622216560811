import React, {useContext} from "react"
import { useStaticQuery, graphql } from "gatsby"
// This reusable React component will manage all of your changes to the document head.
import { Helmet } from 'react-helmet'
import {SiteContext} from "../context/site-context";

const Meta = () => {

  const siteContext = useContext(SiteContext);

  const data = useStaticQuery(graphql`
    query MetaQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  const lang = 'nl';
  const title = data.site.siteMetadata.title;
  const description = data.site.siteMetadata.description;
  const bodyClass=`menu-${siteContext?.menu?.menuState}`;
  const meta = [];

  const getMetaImage = () => {
    const metaImageUrl = null;
    if (metaImageUrl) {
      return metaImageUrl
      // todo gebruik een fixed image size query zodat we de image width en height mee kunnen geven.
      // metaImage.src = metaImageUrl;
      // return metaImage;
    }
    return null;
  };

  const metaImage = getMetaImage();

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      bodyAttributes={{
        class: bodyClass
      }}
      title={title}
      meta={[
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: data.site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
      ]
        .concat(
          metaImage
            ? [
              {
                property: "og:image",
                content: metaImage,
              },
              {
                name: "twitter:card",
                content: "summary_large_image",
              },
            ]
            : [
              {
                name: "twitter:card",
                content: "summary",
              },
            ]
        )
        .concat(meta)
      }
    />
  )

};

Meta.defaultProps = {
  entity: null,
};

export default Meta;
