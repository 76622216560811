import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Navigation from "../navigation/navigation";
import "./header.scss"

export default () => {

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header className="header">

      <div className="left">
        <div className="logo"><Link to="/">{data.site.siteMetadata.title}</Link></div>
      </div>

      <div className="right">
        <Navigation />
      </div>

    </header>
  )
}
