import React from "react";
import "./footer.scss"

const Footer = () => {

  return (
    <div className="footer">
      <ul className="menu">
        <li>© Copyright 2019-{new Date().getFullYear()}</li>
      </ul>
    </div>
  );
};

export default Footer;
