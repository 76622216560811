import React from 'react'
// Runtime type checking for React props and similar objects.
import PropTypes from 'prop-types'

import Meta from '../meta';
import Header from '../header/header';
import "./layout.scss"
import Footer from "../navigation/footer";

const Layout = (props) => {

  // Note: multiple react elements require wrapping <> fragment tag
  // https://reactjs.org/docs/fragments.html
  return (
    <>
      <Meta />
      <div className="layout">
        <Header  />
        <div className="content">
          {props.children}
        </div>
        <Footer/>
      </div>
    </>
  )
};

Layout.defaultProps = {
  entity: null,
  children: null,
};

Layout.propTypes = {
  entity: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default Layout;
