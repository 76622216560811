import React, {useContext} from 'react';
import {Link} from "gatsby";
import "./navigation.scss"
import {SiteContext} from "../../context/site-context";
import Hamburger from "../../assets/svg/icons-feather/menu.svg"
import Close from "../../assets/svg/icons-feather/x.svg";
import Button from "../buttons/button"

function Navigation() {

  const siteContext = useContext(SiteContext);

  return (
    <>
      <div className="menu-hamburger"><Button callback={siteContext.menu?.toggleMenu}><Hamburger/></Button></div>
      <div className={`navigation ${siteContext.menu?.menuState}`}>
        <div className="menu-close"><Button callback={siteContext.menu?.toggleMenu}><Close/></Button></div>
        <ul>
          <li><Link to="/" activeClassName="active" onClick={siteContext.menu?.closeMenu}>Home</Link></li>
          <li><Link to="/het-park" activeClassName="active" onClick={siteContext.menu?.closeMenu}>Het park</Link></li>
          <li><Link to="/het-huisje" activeClassName="active" onClick={siteContext.menu?.closeMenu}>Het huisje</Link></li>
          <li><Link to="/beschikbaarheid" activeClassName="active" onClick={siteContext.menu?.closeMenu}>Beschikbaarheid</Link></li>
          <li><Link to="/contact" activeClassName="active" onClick={siteContext.menu?.closeMenu}>Contact</Link></li>
        </ul>
      </div>
    </>
  );

}

export default Navigation;
